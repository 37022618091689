import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { AiFillBank as SchoolIcon } from "react-icons/ai";
import { AiFillSetting as WorkIcon } from "react-icons/ai";
import { AiTwotoneStar as StarIcon } from "react-icons/ai";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const Timeline: React.FunctionComponent = () => {
  return (
    <>
      <GatsbySeo
        title="Casa Fraine | Timeline"
        description="L'idea e' quella di vivere nella maniera piu' green possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico"
        canonical="https://www.casafraine.com/timeline"
        openGraph={{
          url: "https://www.casafraine.com/timeline",
          title: "Timeline",
          description:
            "L'idea e' quella di vivere nella maniera piu' green possibile nella speranza di una qualche evoluzione e/o creazione di un prodotto unico",
          images: [
            {
              url: "https://www.casafraine.com/mapitaly.png",
              width: 800,
              height: 600,
              alt: "Botanic Api",
            },
            {
              url: "https://www.example.ie/og-image-02.jpg",
              width: 900,
              height: 800,
              alt: "Botanic Api",
            },
            { url: "https://www.example.ie/og-image-03.jpg" },
            { url: "https://www.example.ie/og-image-04.jpg" },
          ],
          site_name: "Casa Fraine",
        }}
        twitter={{
          handle: "Vincenzo Marcovecchio",
          site: "www.casafraine/timeline",
          cardType: "summary_large_image",
        }}
      />

      <section className="timeline booking">
        <h5>What happened after pandemic</h5>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  rgb(33, 150, 243)",
            }}
            date="2020 "
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Got stuck in Nicholas street
            </h3>
            <h4 className="vertical-timeline-element-subtitle">Cork, Ir</h4>
            <p>
              I shit my self in the pants when I discovered somebody was caught
              positive to covid-19 and he was in the same house I was living
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2020"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              We eventually got better
            </h3>
            <h4 className="vertical-timeline-element-subtitle">Cork, Ir</h4>
            <p>
              I had almost completeted the Resort Hotel reservation tutorial
              with create-react-app with really low mental focus and started to
              go out again to buy fish and chips
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2021"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Than I said fuck it I'm going back to Italy
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Fraine, Abruzzo
            </h4>
            <p>
              Can somebody tell me what's going on, hi parents I'm still alive
              by the way, great they stole my money, we got robbers all over the
              place
            </p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={<StarIcon />}
          />
        </VerticalTimeline>
      </section>
    </>
  );
};

export default Timeline;
